import React from 'react';

import styles from './Privilege.module.scss';

interface PrivilegeProps {
  title: string;
  subtitles?: Array<string>;
  warning?: string;
  onRevoke?: () => void;
  onEdit?: () => void;
}

const Privilege = ({ title, subtitles, warning, onEdit, onRevoke }: PrivilegeProps) => (
  <div className={styles.container} data-testid="privilege">
    <div className={styles.text}>
      <div className={styles.title}>{title}</div>
      {subtitles && subtitles.map((subtitle, index) => {
        if (subtitle) 
          return <div key={`${index}`} className={styles.subtitle} title={subtitle}>{subtitle}</div>

        return null;
      })}
      <div className={styles.warning}>{warning}</div>
    </div>
    {onEdit && (
      <button className={styles.edit} onClick={onEdit} type="button">
        Edit
      </button>
    )}
    {onRevoke && (
      <button className={styles.revoke} onClick={onRevoke} type="button">
        Revoke
      </button>
    )}
  </div>
);

export default Privilege;

import { useEffect, useState } from 'react';

import { revokeUserPersonalToken, useFetchUserPersonalTokens } from '@jobandtalent/auth-api-client-v2';
import {
  GenerateUserPersonalToken200,
  UsersPersonalTokenPersonalToken
} from '@jobandtalent/auth-api-client-v2/dist/apiClient/model';
import { Button, Textarea } from '@jobandtalent/design-system';

import Privilege from 'src/components/Privilege/Privilege';
import Section from 'src/components/Section/Section';
import useError, { DEFAULT_ERROR } from 'src/hooks/useError/useError';
import useNotification, { DEFAULT_SUCCESS } from 'src/hooks/useNotification/useNotification';

import PersonalTokenForm from '../NewPersonalTokenForm/NewPersonalTokenForm';
import { useUserState } from '../UserState';

import styles from './EditUserForm.module.scss';

const UserTokensSection = () => {
  const [showTokenForm, setShowTokenForm] = useState(false);
  const { error, setError } = useError();
  const { notification, setNotification } = useNotification();
  const { state } = useUserState();
  const [tokenToShow, setTokenToShow] = useState<UsersPersonalTokenPersonalToken>();

  const {
    data,
    error: personalTokensError,
    mutate
  } = useFetchUserPersonalTokens(state.user?.id as number);

  useEffect(() => {
    if (personalTokensError) {
      setError(personalTokensError);
    }
  }, [personalTokensError]);

  const onCloseNewTokenForm = () => {
    setShowTokenForm(false);
  };

  const onClickRevokeToken = async (tokenId: number) => {
    if (!state.user) {
      return;
    }

    revokeUserPersonalToken(state.user.id, tokenId).then(
      () => mutate(),
      (error) => {
        setError(error?.response?.data ? error.response.data : DEFAULT_ERROR);
      }
    );
  };

  const onSuccessCreateNewToken = (newToken: GenerateUserPersonalToken200) => {
    setTokenToShow(newToken.data.personal_token);
    setNotification(DEFAULT_SUCCESS);
    setShowTokenForm(false);
  };

  return (
    <>
      <Section icon="person_accepted" title="Personal Tokens">
        {tokenToShow ? (
          <Textarea
          label="Copy the token to safe location! After you close this section you won't be able to see the token value again."
          className='errorMessage'
          value={tokenToShow.visible_token}
          height="auto"
          disabled
        />
        ) : null}
        {data?.data.personal_tokens.map(({ id, name }) => (
          <Privilege key={id} onRevoke={() => onClickRevokeToken(id)} title={name} />
        ))}
        <Button
          className={styles.grantPrivilege}
          onSelect={() => setShowTokenForm(true)}
          secondary
          tiny
        >
          Generate new personal token
        </Button>
      </Section>
      {showTokenForm && state.user && (
        <PersonalTokenForm
          onClose={onCloseNewTokenForm}
          selectedUser={state.user}
          onSuccess={onSuccessCreateNewToken}
        />
      )}
      {error}
      {notification}
    </>
  );
};

export default UserTokensSection;

import React, { useMemo } from 'react';

import { revokeAuthorizedUserAccess, usersExport } from '@jobandtalent/auth-api-client-v2';
import {
  UsersUsersItem,
  UsersUsersItemRolesItem
} from '@jobandtalent/auth-api-client-v2/dist/apiClient/model';
import {
  Badge,
  Button,
  CellTextAvatar,
  InputSearch,
  Select,
  Tooltip
} from '@jobandtalent/design-system';

import { formatDistanceToNow } from 'date-fns';
import { useRouter } from 'next/router';

import PATHS from 'src/common/paths.json';
import Table, { getActionsRender, TableFilters } from 'src/components/Table/Table';
import useError, { DEFAULT_ERROR } from 'src/hooks/useError/useError';
import useNotification from 'src/hooks/useNotification/useNotification';

import { UsersTableProps } from '../Users.types';
import { useUserState } from '../UserState';
import useUsersRouter from '../useUsersRouter';

import styles from './UsersTable.module.scss';

const getRoleName: (role: UsersUsersItemRolesItem) => string = (role) =>
  role.role_scope_name ? `${role.role_scope_name}:${role.role_name}` : role.role_name;

const UsersTable = ({ users }: UsersTableProps) => {
  const router = useRouter();
  const {
    query,
    predefinedScenario,
    updateQuery,
    updatePredefinedScenario,
    predefinedScenarioOptions
  } = useUsersRouter();
  const { newUser, editUser, disableUser, invalidateCacheForUser } = useUserState();
  const { setError } = useError();
  const { setNotification } = useNotification();

  const getRolesRender = (userId: number, roles?: UsersUsersItemRolesItem[]) => {
    if (!roles || !roles.length) {
      return '-';
    }
    if (roles.length === 1) {
      return getRoleName(roles[0]);
    }

    const tooltipInfo = (
      <div className={styles.rolesTooltip}>
        <div className={styles.rolesTooltipTitle}>All Roles:</div>
        <ul>
          {roles.map((item: UsersUsersItemRolesItem) => (
            <li key={`${userId}-${item.role_name}`}>{getRoleName(item)}</li>
          ))}
        </ul>
      </div>
    );

    return (
      <div className={styles.roles}>
        {getRoleName(roles[0])}
        <Tooltip className={styles.tooltip} positionX={3} positionY={28} tooltipInfo={tooltipInfo}>
          <Badge
            className={styles.rolesBadge}
            shape="square"
            color="red"
            label={`+${roles.length - 1}`}
          />
        </Tooltip>
      </div>
    );
  };

  const onSelectAction = (type: string, user: UsersUsersItem) => {
    switch (type) {
      case 'edit':
        editUser(user);
        break;
      case 'audit':
        router.push({
          pathname: PATHS.USER_AUDIT,
          query: { userId: user.id }
        });
        break;
      case 'invalidateCache':
        invalidateCacheForUser(user);
        break;
      case 'disable':
        disableUser(user);
        break;
      default:
        break;
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: 'NAME',
        accessor: 'name'
      },
      {
        Header: 'EXTERNAL TOOLS',
        accessor: 'companies_names',
        align: 'right',
        headerAlign: 'right'
      },
      {
        Header: 'SERVICE USER',
        accessor: 'service_user',
        align: 'right',
        headerAlign: 'right'
      },
      {
        Header: 'ROLES',
        accessor: 'roles',
        align: 'right',
        headerAlign: 'right'
      },
      {
        Header: 'LAST LOGIN',
        accessor: (originalRow: UsersUsersItem) =>
          originalRow?.last_login_at
            ? formatDistanceToNow(new Date(originalRow.last_login_at as string))
            : '',
        align: 'right',
        headerAlign: 'right'
      },
      {
        Header: 'ACTIONS',
        accessor: 'actions',
        align: 'right',
        headerAlign: 'right'
      }
    ],
    []
  );

  const formatCompaniesName = (user: UsersUsersItem): string => {
    return user.company_users.map(e => e.company_group_name).filter(e => e).join(", ")
  }
  const formatServiceUser = (accountType: string): string => {
    switch (accountType) {
      case 'service_to_service':
        return "Service to Service";
      case 'lambda_to_service':
        return "Lambda to Service";
      case 'bot':
        return "Bot User";
      default:
        return "Service User";
    }
  };

  const data = useMemo(
    () =>
      users
        ? users.map((user) => ({
            ...user,
            name: (
              <CellTextAvatar avatar={user.picture_url} subtitle={user.email} title={user.name} />
            ),
            roles: getRolesRender(user.id, user.roles),
            actions: getActionsRender(
              (actionType: string) => onSelectAction(actionType, user),
              [
                { value: 'edit', label: 'Edit user' },
                { value: 'audit', label: 'Audit logs' },
                { value: 'invalidateCache', label: 'Invalidate cache' },
                { value: 'disable', label: 'Disable user' }
              ]
            ),
            companies_names: formatCompaniesName(user),
            service_user: user.service_user ? formatServiceUser(user.service_user.account_type || '') : ''
          }))
        : [],
    [users]
  );

  const exportCSV = async () => {
    usersExport({ query: query as string, predefined_scenario: predefinedScenario }).then(
      ({ data }) => {
        setNotification({ title: 'Success', message: data.message });
      },
      () => {
        setError(DEFAULT_ERROR);
      }
    );
  };
  const logout = async () => {
    revokeAuthorizedUserAccess().then(
      () => {
        setNotification({ title: 'Success', message: "logout successful. Bye!" });
        setTimeout(() => {
          window.location.href = '/users';
        }, 2000); // Delay in milliseconds (e.g., 2000ms = 2 seconds)
      },
      () => {
        setError(DEFAULT_ERROR);
      }
    );
  }

  const renderFilters = () => (
    <TableFilters>
      <InputSearch
        onSelect={updateQuery}
        placeholder="Search by user name, email or id"
        value={query}
        aria-label="filter-query"
      />
      <Select
        isClearable
        defaultValue={
          predefinedScenario
            ? predefinedScenarioOptions.find((option) => option.value === predefinedScenario)
            : null
        }
        options={predefinedScenarioOptions}
        onChange={(option) => updatePredefinedScenario(option?.value)}
        placeholder="Filter by predefined scenarios"
        menuPortalTarget={document.body}
        aria-label="filter-predefined-scenario"
      />
      <Button onSelect={exportCSV} secondary className={styles.exportButton}>
        Export to CSV
      </Button>
      <Button onSelect={newUser} small className={styles.newUserButton}>
        New user
      </Button>
      <Button onSelect={logout} small className={styles.logoutButton}>
        Logout
      </Button>
    </TableFilters>
  );

  return <Table columns={columns} data={data} id="users" renderFilters={renderFilters} />;
};

export default UsersTable;

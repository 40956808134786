import React from 'react';

import { AxiosError } from 'axios';
import useSWR from 'swr';

import SnackBarError from 'src/components/SnackBarError/SnackBarError';

import { Error, ErrorMessage } from './useError.types';

export const DEFAULT_ERROR: ErrorMessage = {
  message: 'Unexpected error. Please, try again'
};

const initialState: Error = {
  show: false
};

function useError() {
  const { data, mutate } = useSWR('error', null, {
    fallbackData: initialState
  });

  function renderError(error: ErrorMessage) {
    return {
      show: true,
      render: <SnackBarError error={error} onClose={() => mutate({ show: false })} />
    };
  }

  return {
    error: data?.show ? data?.render : null,
    setError: (error: ErrorMessage) => mutate(renderError(error)),
    handleError: async (error: AxiosError) =>
      mutate(renderError(error?.response?.data as ErrorMessage))
  };
}

export default useError;

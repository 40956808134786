import { useForm } from 'react-hook-form';

import { generateUserPersonalToken } from '@jobandtalent/auth-api-client-v2';
import { ErrorMessage,InputText } from '@jobandtalent/design-system';

import FormInput from "src/components/FormInput/FormInput";
import StackView, { SaveCancelFooter } from "src/components/StackView/StackView"
import useError, { DEFAULT_ERROR } from 'src/hooks/useError/useError';

import { NewPersonalTokenFormProps } from '../Users.types';

const PersonalTokenForm = ({
  onClose,
  selectedUser,
  onSuccess
}: NewPersonalTokenFormProps) => {
  const { error, setError } = useError();

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: {
      personal_token: ''
    }
  });

  const onSubmit = async (data: { personal_token: string }) => {
    generateUserPersonalToken(selectedUser.id, {
      personal_token: { name: data.personal_token }
    }).then(onSuccess, (error) => {
      setError(error?.response?.data ? error?.response?.data : DEFAULT_ERROR);
    });
  };

  const StackViewFooter = <SaveCancelFooter onClose={onClose} onSelect={handleSubmit(onSubmit)} />;

  return (
    <StackView
      footer={StackViewFooter}
      onClose={onClose}
      title="Generate new token"
      nested={true}
    >
      <FormInput label="Name">
        <InputText error={!!errors.personal_token} {...register('personal_token', { required: true })} />
        {!!errors.personal_token && <ErrorMessage>{errors.personal_token.type}</ErrorMessage>}
      </FormInput>
      {error}
    </StackView>
  )
}

export default PersonalTokenForm;

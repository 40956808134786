import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import {
  AXIOS_INSTANCE,
  grantRoleToUser,
  useFetchGrantedRolesToUser
} from '@jobandtalent/auth-api-client-v2';
import { FetchRoles200, Role } from '@jobandtalent/auth-api-client-v2/dist/apiClient/model';
import { Select, Textarea } from '@jobandtalent/design-system';

import useSWRInfinite from 'swr/infinite';

import { formatDataScopesData } from 'src/common/dataScopesHelper';
import FormInput from 'src/components/FormInput/FormInput';
import StackView, { SaveCancelFooter } from 'src/components/StackView/StackView';
import useError, { DEFAULT_ERROR } from 'src/hooks/useError/useError';

import { NewRoleFormProps, RoleOption, TextareaValue } from '../Users.types';

const fetcher = (input: RequestInfo): Promise<FetchRoles200> => {
  return AXIOS_INSTANCE({ url: input as string, method: 'GET' }).then((res) => res.data);
};

const getKey = (pageIndex: number, previousPageData: FetchRoles200) => {
  // reached the end
  if (previousPageData && !previousPageData.meta.next_page === null) {
    return null;
  }

  return `/roles?page=${pageIndex + 1}&per_page=100`; // SWR key
};
import useLocalPermissions from 'src/hooks/useLocalPermissions/useLocalPermissions';

const NewRoleForm = ({ onClose, onSuccess, selectedUser }: NewRoleFormProps) => {
  const {
    data: rolesData,
    error: rolesError,
    size,
    setSize
  } = useSWRInfinite<FetchRoles200>(getKey, fetcher, {
    revalidateFirstPage: false
  });
  const { data: userRolesData } = useFetchGrantedRolesToUser(selectedUser.id);
  const { error, setError } = useError();

  const [roleOptions, setRoleOptions] = useState<RoleOption[]>([]);
  const { permissions, error: currentUserPermissionError } = useLocalPermissions();

  useEffect(() => {
    if (rolesData) {
      // if there's still something to fetch, fetch it before setting select options
      if (rolesData.at(-1)?.meta.next_page !== null) {
        setSize(size + 1);
      } else {
        setRoleOptions(
          rolesData
            .reduce((acc: Role[], rolesPage) => [...acc, ...rolesPage.data.roles], [])
            .filter(
              ({ id }) => !userRolesData?.data.user_roles.find(({ role_id }) => role_id === id)
            )
            .map(({ id, name }) => ({ value: id, label: name }))
        );
      }
    }
  }, [rolesData, userRolesData]);

  useEffect(() => {
    if (rolesError) {
      setError(rolesError);
    }
  }, [rolesError]);

  const onSubmit = async (data: { roleId: RoleOption; dataScopes: TextareaValue }) => {
    grantRoleToUser(selectedUser.id, {
      user_role: userRoleData(data)
    }).then(onSuccess, (error) => {
      setError(error?.response?.data?.message ? error?.response?.data : DEFAULT_ERROR);
    });
  };

  type RoleFormDataTypeWithoutDataScopes = {
    role_id: number;
  };

  type RoleFormDataTypeWithDataScopes = {
    role_id: number;
    data_scope: string[];
  };

  const userRoleData = (data: { roleId: RoleOption; dataScopes: TextareaValue }): RoleFormDataTypeWithDataScopes | RoleFormDataTypeWithoutDataScopes => {
    return {
      role_id: data.roleId.value,
      data_scope: formatDataScopesData(data.dataScopes.value)
    }


    return {
      role_id: data.roleId.value
    }
  }

  const { control, handleSubmit } = useForm<{ roleId: RoleOption; dataScopes: TextareaValue; }>();

  const StackViewFooter = <SaveCancelFooter onClose={onClose} onSelect={handleSubmit(onSubmit)} />;

  return (
    <StackView footer={StackViewFooter} onClose={onClose} nested={true} title="Grant new role">
      <FormInput label="Role name">
        <Controller
          name="roleId"
          control={control}
          render={({ field: { onChange } }) => <Select onChange={onChange} options={roleOptions} />}
        />
      </FormInput>
      {<Controller
        name="dataScopes"
        control={control}
        render={({ field: { onChange } }) => (
          <Textarea
            height={87}
            label="Data Scopes"
            onChange={onChange}
            value={''}
            placeholder="Data scopes must be separated by commas. i.e., company_id:1, company_id:2, company_id:3"
          />
        )}
      />}
      {error}
    </StackView>
  );
};

export default NewRoleForm;

import { useFormContext } from 'react-hook-form';

import { InputText } from '@jobandtalent/design-system';

import FormInput from 'src/components/FormInput/FormInput';
import Section from 'src/components/Section/Section';

const UserInfoSection = () => {
  const { register, formState: { errors } } = useFormContext(); // Use useFormContext to get register and errors.

  return (
    <Section icon="info" title="User info">
      <FormInput label="UID">
        <InputText disabled {...register('id', { required: true })} />
      </FormInput>
      <FormInput label="User name">
        <InputText error={!!errors.name} {...register('name', { required: true })} />
      </FormInput>
      <FormInput label="User email">
        <InputText error={!!errors.email} {...register('email', { required: true })} />
      </FormInput>
    </Section>
  );
};

export default UserInfoSection;
import React, { useEffect } from 'react';

import { invalidateUserPermissionsCache } from '@jobandtalent/auth-api-client-v2';

import Pagination from 'src/components/Pagination/Pagination';
import useError, { DEFAULT_ERROR } from 'src/hooks/useError/useError';
import useNotification from 'src/hooks/useNotification/useNotification';
import DisableUser from 'src/pages/Users/DisableUser/DisableUser';
import UsersTable from 'src/pages/Users/UsersTable/UsersTable';

import EditUserForm from './EditUserForm/EditUserForm';
import NewUserForm from './NewUserForm/NewUserForm';
import { UsersProps } from './Users.types';
import { useUserState } from './UserState';
import useUsersRouter from './useUsersRouter';

const Users = ({ users }: UsersProps) => {
  const { usersData, mutateUsers } = useUsersRouter();
  const { state, resetUserSelection } = useUserState();
  const { error, setError } = useError();
  const { notification, setNotification } = useNotification();

  useEffect(() => {
    if (state.status === 'invalidate' && state.user) {
      invalidateUserPermissionsCache(state.user.id).then(
        () => {
          setNotification({ title: 'Permission cache', message: 'Invalidated!' });
          resetUserSelection();
        },
        () => {
          setError(DEFAULT_ERROR);
        }
      );
    }
  }, [state.status]);

  return (
    <>
      <UsersTable users={users}></UsersTable>
      <Pagination
        totalCount={usersData?.meta.total_count}
        totalPages={usersData?.meta.total_pages}
      />
      {state.status === 'new' && <NewUserForm />}
      {state.status === 'edit' && <EditUserForm />}
      {state.status === 'disable' && (
        <DisableUser
          onSuccess={() => {
            mutateUsers();
            setNotification({ title: 'Disable user', message: 'Disabled!' });
          }}
          onError={() => {
            setError(DEFAULT_ERROR);
          }}
        />
      )}
      {error}
      {notification}
    </>
  );
};

export default Users;

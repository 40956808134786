import React from 'react';

import { FormInputProps } from './FormInput.types';
import styles from './FormInput.module.scss';

const FormInput = ({ label, children }: FormInputProps) => {
  return (
    <div className={styles.input}>
      <div className={styles.label}>{label}</div>
      {children}
    </div>
  );
};

export default FormInput;

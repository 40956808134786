import React from 'react';
import ScrollLock from 'react-scrolllock';

import { Button, StackView as JTStackView } from '@jobandtalent/design-system';

import classNames from 'classnames';

import useClickOnDocument from 'src/hooks/useClickOnDocument/useClickOnDocument';

import { SaveCancelFooterProps, StackViewProps } from './StackView.types';
import styles from './StackView.module.scss';

const StackView = ({ footer, onClose, title, nested, children }: StackViewProps) => {
  useClickOnDocument(onClose, (target) => {
    if (!nested) {
      return !!(
        !target.closest(`.${styles.stackView}`) &&
        !target.closest(`.${styles.nestedContainer}`) &&
        target.closest(`.${styles.container}`)
      );
    }

    return !!(
      !target.closest(`.${styles.nestedStackView}`) && target.closest(`.${styles.nestedContainer}`)
    );
  });

  return (
    <ScrollLock>
      <div
        className={classNames(styles.container, {
          [styles.nestedContainer]: nested
        })}
        data-testid={title}
      >
        <JTStackView
          className={classNames(styles.stackView, {
            [styles.nestedStackView]: nested
          })}
          footer={footer}
          onClose={onClose}
          title={title}
        >
          <div className={styles.stackViewContent}>{children}</div>
        </JTStackView>
      </div>
    </ScrollLock>
  );
};

export const SaveCancelFooter = ({ onClose, onSelect, isSaveDisabled }: SaveCancelFooterProps) => (
  <div>
    <Button onSelect={onClose} secondary>
      Cancel
    </Button>
    <Button disabled={isSaveDisabled} className={styles.save} onSelect={onSelect}>
      Save
    </Button>
  </div>
);

export default StackView;

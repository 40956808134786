import { useForm } from 'react-hook-form';

import { generateServiceUserToken } from '@jobandtalent/auth-api-client-v2';
import { ErrorMessage,InputText } from '@jobandtalent/design-system';

import FormInput from "src/components/FormInput/FormInput";
import StackView, { SaveCancelFooter } from "src/components/StackView/StackView"
import useError, { DEFAULT_ERROR } from 'src/hooks/useError/useError';

import { NewServiceUserTokenFormProps } from '../Users.types';

const ServiceTokenForm = ({
  onClose,
  selectedUser,
  onSuccess
}: NewServiceUserTokenFormProps) => {
  const { error, setError } = useError();

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: {
      service_token: ''
    }
  });

  const onSubmit = async (data: { service_token: string }) => {
    generateServiceUserToken(selectedUser.id, {
      service_token: { name: data.service_token }
    }).then(onSuccess, (error) => {
      setError(error?.response?.data ? error?.response?.data : DEFAULT_ERROR);
    });
  };

  const StackViewFooter = <SaveCancelFooter onClose={onClose} onSelect={handleSubmit(onSubmit)} />;

  return (
    <StackView
      footer={StackViewFooter}
      onClose={onClose}
      title="Generate new token"
      nested={true}
    >
      <FormInput label="Name">
        <InputText error={!!errors.service_token} {...register('service_token', { required: true })} />
        {!!errors.service_token && <ErrorMessage>{errors.service_token.type}</ErrorMessage>}
      </FormInput>
      {error}
    </StackView>
  )
}

export default ServiceTokenForm;

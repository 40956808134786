import { useEffect } from 'react';

const useClickOnDocument = (
  callback: () => void,
  validation: (htmlElement: HTMLElement) => boolean
) => {
  useEffect(() => {
    const onClickDocument = (e: MouseEvent) => {
      if (validation(e.target as HTMLElement)) {
        callback();
      }
    };

    document.addEventListener('click', onClickDocument);

    return () => {
      document.removeEventListener('click', onClickDocument);
    };
  }, [callback]);
};

export default useClickOnDocument;

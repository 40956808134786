
export const formatDataScopesData = (dataScopes: string): string[] => {
  if (dataScopes) {
    return dataScopes.split(/\s{0,},\s{0,}/).map(e => e.trim()).filter(e => e);
  }
  
  return [];
}

export const formatDataScopesInput = (data_scopes: string[] | null): string => {
  if (data_scopes && Array.isArray(data_scopes)) {
    return data_scopes.join(",")
  }

  return "";
}
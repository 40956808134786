import { useEffect, useState } from 'react';

import { revokePermissionToUser, useFetchUserPermissions } from '@jobandtalent/auth-api-client-v2';
import { UsersPermission } from '@jobandtalent/auth-api-client-v2/dist/apiClient/model';
import { FetchUserPermissions200DataUserPermissionsItem } from '@jobandtalent/auth-api-client-v2/dist/apiClient/model/fetchUserPermissions200DataUserPermissionsItem';
import { Button } from '@jobandtalent/design-system';

import Privilege from 'src/components/Privilege/Privilege';
import Section from 'src/components/Section/Section';
import useError, { DEFAULT_ERROR } from 'src/hooks/useError/useError';
import useLocalPermissions from 'src/hooks/useLocalPermissions/useLocalPermissions';
import useNotification, { DEFAULT_SUCCESS } from 'src/hooks/useNotification/useNotification';

import PermissionForm from '../PermissionForm/PermissionForm';
import { useUserState } from '../UserState';

import styles from './EditUserForm.module.scss';

const UserPermissionsSection = () => {
  const { state } = useUserState();
  const { error, setError } = useError();
  const { notification, setNotification } = useNotification();
  const [selectedPermission, setSelectedPermission] = useState<UsersPermission | null>();
  const [showPermissionForm, setShowPermissionForm] = useState(false);

  const {
    data: userPermissionsData,
    error: userPermissionsError,
    mutate: userPermissionsMutate
  } = useFetchUserPermissions(state.user?.id as number);

  const COMPANIES_APP_PERMISSIONS_PREFIX = 'companies_app:';

  const { permissions, error: permissionError } = useLocalPermissions();

  const companyAdminPermission = !permissionError && permissions.companyAdminPermission;

  const onClickRevokePermission = async (permissionId: number) => {
    if (!state.user) {
      return;
    }

    revokePermissionToUser(state.user.id, permissionId).then(
      () => userPermissionsMutate(),
      (error) => {
        setError(error?.response?.data ? error?.response?.data : DEFAULT_ERROR);
      }
    );
  };

  const onClosePermissionForm = () => {
    setShowPermissionForm(false);
    setSelectedPermission(null);
  };

  const onSuccessPermissionForm = () => {
    userPermissionsMutate();
    setNotification(DEFAULT_SUCCESS);
    setShowPermissionForm(false);
    setSelectedPermission(null);
  };

  useEffect(() => {
    if (userPermissionsError) {
      setError(userPermissionsError);
    }
  }, [userPermissionsError]);

  return (
    <>
      <Section icon="person_accepted" title="Permissions">
        {userPermissionsData?.data.user_permissions?.map((permission) => (
          <Privilege
            key={permission.id}
            title={permission.name}
            subtitles={[permission.resource_ids || '-', datascopeValue(permission)]}
            onRevoke={!companyAdminPermission && permission.name.startsWith(COMPANIES_APP_PERMISSIONS_PREFIX) ? undefined : () => onClickRevokePermission(permission.id)}
            onEdit={!companyAdminPermission && permission.name.startsWith(COMPANIES_APP_PERMISSIONS_PREFIX) ? undefined : () => {
              setShowPermissionForm(true);
              setSelectedPermission(permission);
            }}
          />
        ))}
        <Button
          className={styles.grantPrivilege}
          onSelect={() => setShowPermissionForm(true)}
          secondary
          tiny
        >
          Grant permission
        </Button>
      </Section>
      {showPermissionForm && state.user && (
        <PermissionForm
          onClose={onClosePermissionForm}
          onSuccess={onSuccessPermissionForm}
          selectedUser={state.user}
          selectedPermission={selectedPermission}
        />
      )}
      {error}
      {notification}
    </>
  );
};

const datascopeValue = (permission: FetchUserPermissions200DataUserPermissionsItem): string => {
  if(permission.data_scope && permission.data_scope.length > 0) {
    return `Data scopes: ${permission.data_scope.join(", ")}`;
  }

  return "";
}
export default UserPermissionsSection;

import { useCallback, useEffect, useRef, useState } from 'react';

import axios from 'axios';

type LocalPermissions = {
  personalTokens?: boolean;
  companyAdminPermission?: boolean;
  serviceUsersPermission?: boolean;
};

function useLocalPermissions() {
  const [permissions, setPermissions] = useState<LocalPermissions>({});
  const [error, setError] = useState(false);
  const mountedRef = useRef(true);

  const execute = useCallback(() => {
    return axios('/api/me/permissions')
      .then((result) => {
        if (!mountedRef.current) {
          return null;
        }

        setPermissions(result.data);
        setError(false);

        return result;
      })
      .catch((err) => {
        if (!mountedRef.current) {
          return null;
        }

        setError(true);

        throw err;
      });
  }, []);

  useEffect(() => {
    execute();

    return () => {
      mountedRef.current = false;
    };
  }, []);

  return { permissions, error };
}

export default useLocalPermissions;

import React from 'react';

import {
  Select,
  SelectDisclosureContainer,
  SelectFixedWidthMenu,
  Table as JTTable
} from '@jobandtalent/design-system';
import {
  BaseTableRecord,
  TableProps
} from '@jobandtalent/design-system/dist/components/organisms/Table/Table.types';

import classNames from 'classnames';

import styles from './Table.module.scss';

const Table = (props: TableProps<BaseTableRecord>) => {
  return (
    <div className={styles.container}>
      <JTTable {...props} />
    </div>
  );
};

type TableFilterProps = {
  className?: string;
  children: React.ReactNode;
};

export const TableFilters = ({ className, children }: TableFilterProps) => (
  <div className={classNames(className, styles.filters)}>{children}</div>
);

export const getActionsRender = (
  onSelectAction: (actionType: string) => void,
  options: { value: string; label: string }[]
) => (
  <Select
    className={styles.actions}
    customComponents={{
      SelectContainer: (props) => <SelectDisclosureContainer {...props} text="Actions" />,
      Menu: (props) => <SelectFixedWidthMenu {...props} width={200} />
    }}
    menuPortalTarget={document.body}
    mode="dropdown"
    styles={{
      menuPortal: (base) => {
        return { ...base, left: 'auto', right: 0, transform: 'translateX(-170px)' }; // width - body padding
      }
    }}
    onChange={(item) => {
      onSelectAction(item?.value ?? '');
    }}
    options={options}
    aria-label="Actions"
  />
);

export default Table;

import React, { useEffect, useState } from 'react';
import { FieldError, useFormContext, UseFormRegister } from 'react-hook-form';

import type { UsersUsersItemCompanyUsersItem } from '@jobandtalent/auth-api-client-v2/dist/apiClient/model';
import { Checkbox, InputText } from '@jobandtalent/design-system';

import { format, formatDistanceToNow } from 'date-fns';

import FormInput from 'src/components/FormInput/FormInput';
import Section from 'src/components/Section/Section';
import useLocalPermissions from 'src/hooks/useLocalPermissions/useLocalPermissions';

import styles from './EditUserForm.module.scss';

export type CompanyUserInfoType = {
  register: UseFormRegister<{
    locale: string | undefined
    time_zone: string | undefined
    phone_number: string | undefined
    country_code: string | undefined
    company_user: boolean | undefined
  }>;
  errors: {
    errorsid?: FieldError | undefined;
    locale?: FieldError | undefined;
    time_zone?: FieldError | undefined;
    phone_number?: FieldError | undefined;
    country_code: string | undefined
    company_user?: FieldError | undefined;
  };
  company_users: UsersUsersItemCompanyUsersItem[];
};

const CompaniesAppId = "0";

const CompanyUsersInfo = ({ company_users }: { company_users: UsersUsersItemCompanyUsersItem[] | undefined; }) => {
  const { permissions, error: permissionError } = useLocalPermissions();
  const companyAdminPermission = !permissionError && permissions.companyAdminPermission
  const isDisabled = company_users?.some(cu => cu.external_company_group_id === CompaniesAppId) || false;


  useEffect(() => {
    if(isDisabled) {
      setCompanyUserFlag(true);
      setValue('company_user', true);
    }
  }, [isDisabled]);
  const { register, formState: { errors } } = useFormContext();
  const [companyUserFlag, setCompanyUserFlag] = useState(false);
  const { setValue } = useFormContext();

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCompanyUserFlag(event.target.checked);
    setValue('company_user', event.target.checked);
    const required = event.target.checked ? true : false;
    register('locale', { required });
    register('time_zone', { required });
    register('country_code', { required });
  };


  return (
    <Section icon="info" title="Company info" defaultCollapsed={true}>
      <FormInput label="">
        <Checkbox
          {...register('company_user', { required: false })}
          disabled={isDisabled || !companyAdminPermission}
          checked={companyUserFlag}
          onChange={handleCheckboxChange}
          label="Companies APP User"
          name="Companies APP User"
          id="company_user"
        />
    </FormInput>
      <FormInput label="User locale">
        <InputText placeholder="Supported currently en-GB or es-ES" error={!!errors.locale} {...register('locale')} />
      </FormInput>
      <FormInput label="User time zone">
        <InputText placeholder="Europe/Madrid, Europe/London, ..." error={!!errors.time_zone} {...register('time_zone')} />
      </FormInput>
      <FormInput label="User phone number">
        <InputText placeholder="+341234567" error={!!errors.phone_number} {...register('phone_number')} />
      </FormInput>
      <FormInput label="User country code">
        <InputText placeholder="ES" error={!!errors.country_code} {...register('country_code')} />
      </FormInput>
      {company_users?.map((user, index) => (
        <div className={styles.externalToolBlock} key={index}>
          <FormInput label={`External tool`}>
          <InputText disabled value={user.company_group_name + (user.external_company_group_id ? " (" + user.external_company_group_id + ")" : "")} />
          </FormInput>
          <FormInput label={`External IDP User ID`}>
            <InputText disabled value={user.external_idp_user_id || ""} />
          </FormInput>
          <FormInput label={`Last Login At`}>
            <InputText disabled value={user.last_login_at
            ? formatDistanceToNow(new Date(user.last_login_at as string)) + " " +  "(" + format(new Date(user.last_login_at), 'yyyy-MM-dd HH:mm:ss') +")" 
            : ""} />
          </FormInput>
        </div>
      ))}
    </Section>
  );
};

export default CompanyUsersInfo;

import { useEffect, useState } from 'react';

import { revokeRoleToUser, useFetchGrantedRolesToUser } from '@jobandtalent/auth-api-client-v2';
import { FetchGrantedRolesToUser200DataUserRolesItem } from '@jobandtalent/auth-api-client-v2/dist/apiClient/model/fetchGrantedRolesToUser200DataUserRolesItem';
import { Button } from '@jobandtalent/design-system';

import Privilege from 'src/components/Privilege/Privilege';
import Section from 'src/components/Section/Section';
import useError, { DEFAULT_ERROR } from 'src/hooks/useError/useError';
import useNotification, { DEFAULT_SUCCESS } from 'src/hooks/useNotification/useNotification';

import NewRoleForm from '../NewRoleForm/NewRoleForm';
import { useUserState } from '../UserState';

import styles from './EditUserForm.module.scss';

const UserRolesSection = () => {
  const { state } = useUserState();
  const { error, setError } = useError();
  const { notification, setNotification } = useNotification();
  const [showNewRoleForm, setShowNewRoleForm] = useState(false);

  const {
    data: userRolesData,
    error: userRolesError,
    mutate: userRolesMutate
  } = useFetchGrantedRolesToUser(state.user?.id as number);

  const onClickRevokeRole = async (roleId: number) => {
    if (!state.user) {
      return;
    }

    revokeRoleToUser(state.user.id, roleId).then(
      () => userRolesMutate(),
      (error) => {
        setError(error?.response?.data ? error.response.data : DEFAULT_ERROR);
      }
    );
  };

  const onCloseNewRoleForm = () => {
    setShowNewRoleForm(false);
  };

  const onSuccessNewRoleForm = () => {
    userRolesMutate();
    setNotification(DEFAULT_SUCCESS);
    setShowNewRoleForm(false);
  };

  useEffect(() => {
    if (userRolesError) {
      setError(userRolesError);
    }
  }, [userRolesError]);

  return (
    <>
      <Section icon="person_accepted" title="Roles">
        {userRolesData?.data.user_roles.map((role) => (
          <Privilege
            key={role.id}
            onRevoke={() => onClickRevokeRole(role.role_id)}
            title={role.role_name}
            subtitles={[role.role_scope_name || '-', datascopeValue(role)]}
          />
        ))}
        <Button
          className={styles.grantPrivilege}
          dataAttributes={{
            testid: 'grantRoleButton'
          }}
          onSelect={() => setShowNewRoleForm(true)}
          secondary
          tiny
        >
          Grant role
        </Button>
      </Section>
      {showNewRoleForm && state.user && (
        <NewRoleForm
          onClose={onCloseNewRoleForm}
          onSuccess={onSuccessNewRoleForm}
          selectedUser={state.user}
        />
      )}
      {error}
      {notification}
    </>
  );
};

const datascopeValue = (role: FetchGrantedRolesToUser200DataUserRolesItem): string => {
  if(role.data_scope && role.data_scope.length > 0) {
    return `Data scopes: ${role.data_scope.join(", ")}`;
  }

  return "";
}

export default UserRolesSection;

import React from 'react';

import { Pagination as JTPagination } from '@jobandtalent/design-system';

import classNames from 'classnames';
import { useRouter } from 'next/router';

import { PaginationOnChange, PaginationProps } from './Pagination.types';
import styles from './Pagination.module.scss';

export const FIRST_PAGE = 1;
export const DEFAULT_PER_PAGE = 25;

const Pagination = ({ className, windowSize = 3, ...rest }: PaginationProps) => {
  const router = useRouter();
  const page = Number(router.query.page) || FIRST_PAGE;
  const perPage = Number(router.query.per_page) || DEFAULT_PER_PAGE;

  const onChange: PaginationOnChange = ({ page, per_page }) => {
    router.push({
      pathname: router.pathname,
      query: {
        ...router.query,
        page,
        per_page
      }
    });
  };
  return (
    <JTPagination
      className={classNames(styles.base, className)}
      currentPage={page}
      limitValue={perPage}
      windowSize={windowSize}
      onChange={onChange}
      {...rest}
    />
  );
};

export default Pagination;

import React from 'react';

import { SnackBar } from '@jobandtalent/design-system';

import useSWR from 'swr';

import { Notification, NotificationMessage } from './useNotification.types';
import styles from './useNotification.module.scss';

export const DEFAULT_SUCCESS: NotificationMessage = {
  title: 'Success',
  message: 'The operation was successful.'
};

const initialState: Notification = {
  show: false
};

function useNotification() {
  const { data, mutate } = useSWR('notification', null, {
    fallbackData: initialState
  });

  function renderNotification(title: string, message: string) {
    return {
      show: true,
      render: (
        <SnackBar
          className={styles.container}
          hideTimeout={8000}
          onDismiss={() => mutate({ show: false })}
          title={title}
        >
          {message}
        </SnackBar>
      )
    };
  }

  return {
    notification: data?.show ? data?.render : null,
    setNotification: ({ title, message }: { title: string; message: string }) =>
      mutate(renderNotification(title, message))
  };
}

export default useNotification;

import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { updateUser } from '@jobandtalent/auth-api-client-v2';
import { fetchAuthorizedUser } from '@jobandtalent/auth-api-client-v2';
import type { FetchAuthorizedUser200Data, RegisterNewUserBodyUserServiceUserInfoAccountType, UsersUsersItem, UsersUsersItemCompanyUsersItem } from '@jobandtalent/auth-api-client-v2/dist/apiClient/model';

import getConfig from 'next/config';

import StackView, { SaveCancelFooter } from 'src/components/StackView/StackView';
import useError, { DEFAULT_ERROR } from 'src/hooks/useError/useError';
import useLocalPermissions from 'src/hooks/useLocalPermissions/useLocalPermissions';

import { useUserState } from '../UserState';
import useUsersRouter from '../useUsersRouter';

import CompanyUserInfoSection from './CompanyUserInfoSection';
import CompanyUsersInfo from './CompanyUserSection';
import ServiceUserSection from './ServiceUserSection';
import ServiceUserTokensSection from './ServiceUserTokensSection';
import UserInfoSection from './UserInfoSection';
import UserPermissionsSection from './UserPermissionsSection';
import UserPersonalTokensSection from './UserPersonalTokensSection';
import UserRolesSection from './UserRolesSection';

const { publicRuntimeConfig } = getConfig();

const EditUserForm = () => {
  const { state, resetUserSelection } = useUserState();
  const { permissions, error: permissionError } = useLocalPermissions();
  const [currentUser, setCurrentUser] = useState<FetchAuthorizedUser200Data | null>(null);
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);
  const onClose = resetUserSelection;
  const { mutateUsers } = useUsersRouter();
  useEffect(() => {
    const getCurrentUser = async () => {
      const response = await fetchAuthorizedUser();
      setCurrentUser(response.data);
    }

    getCurrentUser();
  }, []);

  const notProduction = (publicRuntimeConfig.APP_ENV !== "production");

  const methods = useForm({
    defaultValues: {
      id: state.user?.id,
      name: state.user?.name,
      email: state.user?.email,
      locale: state.user?.locale,
      time_zone: state.user?.time_zone,
      phone_number: state.user?.phone_number,
      country_code: state.user?.country_code,
      company_users: state.user?.company_users,
      company_user: state.user?.company_users?.some((cu:UsersUsersItemCompanyUsersItem) => cu.company_group_id === null) || false,
      owner: state.user?.service_user?.owner || '',
      description: state.user?.service_user?.description || '',
      account_type: state.user?.service_user?.account_type || ''
    }
  });

  const { error, setError } = useError();

  const onSubmit = async (formData: {
                    name?: string; 
                    email?: string, 
                    locale?: UsersUsersItem['locale'], 
                    time_zone?: UsersUsersItem['time_zone'], 
                    company_user?: boolean,
                    phone_number?: UsersUsersItem['phone_number'],
                    country_code?: UsersUsersItem['country_code'], 
                    service_user_flag?: boolean,
                    owner?: string,
                    description?: string,
                    account_type?: string
  }) => {
    if (!state.user || !formData.name || !formData.email) {
      return;
    }

    if (formData.company_user && (!formData.time_zone || !formData.locale || !formData.country_code)) {
      return;
    }

    const CompaniesAppId = "0";
    const is_company_user = (company_users: UsersUsersItem['company_users']) => {
      return company_users?.some(cu => cu.external_company_group_id === CompaniesAppId) || false;
    }

    setIsSaveDisabled(true);
    updateUser(state.user.id, {
      user: {
        name: formData.name,
        email: formData.email,
        locale: formData.locale || undefined,
        time_zone: formData.time_zone || undefined,
        phone_number: formData.phone_number || undefined,
        country_code: formData.country_code || undefined,
        company_user: state.user?.company_users?.some((cu:UsersUsersItemCompanyUsersItem) => cu.external_company_group_id === CompaniesAppId) || formData.company_user,
        service_user: state.user?.service_user?.id != null,
        service_user_info: {
          owner: formData.owner,
          description: formData.description,
          account_type: formData.account_type as RegisterNewUserBodyUserServiceUserInfoAccountType,
        }
      }
    }).then(
      () => {
        mutateUsers();
        resetUserSelection();
      },
      (error) => {
        setIsSaveDisabled(false);
        setError(error?.response?.data ? error?.response?.data : DEFAULT_ERROR);
      }
    );
  };

  const { handleSubmit } = methods;
  const personalTokensPermission = !permissionError && permissions.personalTokens
  const showServiceTokens = personalTokensPermission && state.user?.service_user;
  const showPersonalTokens = personalTokensPermission && currentUser?.user?.id === state.user?.id && !state.user?.service_user;
  const StackViewFooter = <SaveCancelFooter isSaveDisabled={isSaveDisabled} onClose={onClose} onSelect={handleSubmit(onSubmit)} />;

  return (
    <FormProvider {...methods}>
      <>
        <StackView footer={StackViewFooter} onClose={onClose} title="Edit user">
          { state.user?.company_users.length > 0 ? <CompanyUserInfoSection/> : <UserInfoSection/> }
          {
            !state.user?.service_user && <CompanyUsersInfo company_users={state.user?.company_users} />
          }
          {
            state.user?.service_user &&
            <ServiceUserSection service_user_items={state.user.service_user} />
          }
          <UserRolesSection />
          <UserPermissionsSection />
          { showServiceTokens && <ServiceUserTokensSection />}
          { showPersonalTokens && <UserPersonalTokensSection />}

          {error}
        </StackView>
      </>
    </FormProvider>
  );
};

export default EditUserForm;

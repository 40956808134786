import React from 'react';

import { SectionTitle } from '@jobandtalent/design-system';

import { SectionProps } from './Section.types';
import styles from './Section.module.scss';

const Section = ({ icon, title, children, defaultCollapsed }: SectionProps) => {
  return (
    <SectionTitle
      className={styles.section}
      classNameIcon={styles.sectionIcon}
      icon={icon}
      principal
      title={title}
      defaultCollapsed={defaultCollapsed}
    >
      <div className={styles.sectionContent}>{children}</div>
    </SectionTitle>
  );
};

export default Section;

import { disableUser } from '@jobandtalent/auth-api-client-v2';
import { Modals } from '@jobandtalent/design-system';

import { DisableUserProps } from '../Users.types';
import { useUserState } from '../UserState';

const DisableUser = ({ onSuccess, onError }: DisableUserProps) => {
  const { state, resetUserSelection } = useUserState();

  const onConfirm = () => {
    if (state.user) {
      disableUser(state.user.id).then(onSuccess, onError);
    }
  };

  return (
    <Modals
      onConfirm={onConfirm}
      hideModal={resetUserSelection}
      title="Invalidate user"
      subtitle="Are you sure?"
      danger
      showModal={() => null}
    />
  );
};

export default DisableUser;

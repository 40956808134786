import React, { useEffect } from 'react';

import { Breadcrumb, SubHeader } from '@jobandtalent/design-system';

import { NextPage } from 'next';
import Head from 'next/head';
import Link from 'next/link';

import PATHS from 'src/common/paths.json';
import LoaderFullSize from 'src/components/LoaderFullSize/LoaderFullSize';
import useError from 'src/hooks/useError/useError';
import Users from 'src/pages/Users/Users';
import useUsersRouter from 'src/pages/Users/useUsersRouter';

const UsersPage: NextPage = () => {
  const { isValidating, usersData, usersError } = useUsersRouter();
  const { error, setError } = useError();

  useEffect(() => {
    if (usersError) {
      setError(usersError);
    }
  }, [usersError]);

  return (
    <>
      <Head>
        <title>Auth • Users</title>
      </Head>
      {isValidating || !usersData ? (
        <LoaderFullSize />
      ) : (
        <>
          <SubHeader
            className="page__header"
            breadcrumb={
              <Breadcrumb theme="medium">
                <Link href={PATHS.HOME}>
                  <a>Auth</a>
                </Link>
                <span>Users</span>
              </Breadcrumb>
            }
            simple
          />
          <Users users={usersData.data?.users} />
        </>
      )}
      {error}
    </>
  );
};

export default UsersPage;

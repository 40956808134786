import React from 'react';
import { createPortal } from 'react-dom';

import { SnackBar } from '@jobandtalent/design-system';

import { ErrorMessageErrors } from 'src/hooks/useError/useError.types';

import { SnackBarErrorProps } from './SnackBarError.types';
import styles from './SnackBarError.module.scss';

const SnackBarError = ({ error, onClose }: SnackBarErrorProps) => {
  const renderContent = (errors: ErrorMessageErrors) =>
    Object.keys(errors).map((category) =>
      Object.keys(errors[category]).map((key) =>
        errors[category][key].map((item) => (
          <div
            key={`${category}${key}${item.error}`}
          >{`${category}/${key}: ${item.localized_error}`}</div>
        ))
      )
    );

  return error
    ? createPortal(
        <SnackBar
          className={styles.container}
          error
          hideTimeout={8000}
          onDismiss={onClose}
          title={error.message}
        >
          {error.errors && renderContent(error.errors)}
        </SnackBar>,
        document.body
      )
    : null;
};

export default SnackBarError;

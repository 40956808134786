import { shouldFetch, useFetchUsers } from '@jobandtalent/auth-api-client-v2';
import { FetchUsersPredefinedScenario } from '@jobandtalent/auth-api-client-v2/dist/apiClient/model';

import { useRouter } from 'next/router';

import { DEFAULT_PER_PAGE, FIRST_PAGE } from 'src/components/Pagination/Pagination';

import { PredefinedScenarioOptions } from './Users.types';

export const predefinedScenarios: { [K in FetchUsersPredefinedScenario]: string } = {
  without_role_assigned: 'Without role assigned',
  with_more_than_one_role_assigned: 'With more than one role assigned',
  with_old_roles_assigned: 'With old roles assigned',
  with_direct_permissions_assigned: 'With direct permissions assigned',
  last_activity_before_than_one_month_ago: 'Last activity before than one month ago',
  last_activity_before_than_two_months_ago: 'Last activity before than two months ago',
  not_jobandtalent_users: 'Not jobandtalent users',
  company_app_users: 'Company App users',
  seat_users: 'Seat users',
  service_users: 'Service Users',
  job_and_talent_users: 'JobAndTalent Users'

};

function useUsersRouter() {
  const router = useRouter();
  const query = (router.query.query as string) || undefined;
  const perPage = Number(router.query.per_page) || DEFAULT_PER_PAGE;
  const page = Number(router.query.page) || FIRST_PAGE;
  const predefinedScenario =
    (router.query.predefined_scenario as FetchUsersPredefinedScenario) || undefined;

  const updateQuery = (text: string) => {
    router.push({
      pathname: router.pathname,
      query: {
        ...router.query,
        query: text,
        page: FIRST_PAGE
      }
    });
  };

  const updatePredefinedScenario = (text: FetchUsersPredefinedScenario | undefined) => {
    router.push({
      pathname: router.pathname,
      query: {
        ...router.query,
        predefined_scenario: text,
        page: FIRST_PAGE
      }
    });
  };

  const predefinedScenarioOptions: PredefinedScenarioOptions[] = Object.entries(
    predefinedScenarios
  ).map(([key, value]) => ({
    value: key as FetchUsersPredefinedScenario,
    label: value
  }));

  const {
    data: usersData,
    error: usersError,
    isValidating,
    mutate: mutateUsers
  } = useFetchUsers(
    { query, page, per_page: perPage, predefined_scenario: predefinedScenario },
    shouldFetch(router.isReady)
  );

  return {
    query,
    predefinedScenario,
    isValidating,
    usersData,
    usersError,
    predefinedScenarioOptions,
    updateQuery,
    updatePredefinedScenario,
    mutateUsers
  };
}

export default useUsersRouter;

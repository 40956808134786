import { UsersUsersItem } from '@jobandtalent/auth-api-client-v2/dist/apiClient/model';

import useSWR from 'swr';

export type UserState = {
  status: 'new' | 'disable' | 'edit' | 'reset' | 'invalidate';
  user?: UsersUsersItem | null;
};

const initialState: UserState = {
  status: 'reset',
  user: null
};

function useUserState() {
  const { data, mutate } = useSWR('users', null, {
    fallbackData: initialState
  });

  return {
    state: data || initialState,
    newUser: () => mutate({ status: 'new' }),
    editUser: (user: UsersUsersItem) => mutate({ status: 'edit', user }),
    disableUser: (user: UsersUsersItem) => mutate({ status: 'disable', user }),
    invalidateCacheForUser: (user: UsersUsersItem) => mutate({ status: 'invalidate', user }),
    resetUserSelection: () => mutate({ status: 'reset', user: null })
  };
}

export { useUserState };
